html{
	scroll-padding-top: 30%;
}
html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'Veneer';
	src: url('/fonts/Veneer.woff2') format('woff2'), url('/fonts/Veneer.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'TradeGothicLTPro';
	src: local('TradeGothicLTPro'), url(/fonts/TradeGothicLTPro.ttf) format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'TradeGothicLTPro-Bold';
	src: local('TradeGothicLTPro-Bold'), url(/fonts/TradeGothicLTPro-Bold.ttf) format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'jenna-sue';
	src: local('jenna-sue'), url(/fonts/Jenna\ Sue.woff2) format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'TradeGothic';
	src: url('/fonts/Trade-Gothic-LT-Condensed-No-18.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TradeGothic';
	src: url('/fonts/Trade-Gothic-LT-Bold-Condensed-No-20.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IntroRust';
	src: url('/fonts/IntroRust.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('/fonts/Teko-Light.woff') format('woff');
	font-weight: lighter;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('/fonts/Teko-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'OYSheila';
	src: url('/fonts/OY_SHEILA_01v1.woff') format('woff');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Richardson-Script';
	src: url('/fonts/Richardson-Script.woff') format('woff');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/Roboto.woff2') format('woff2');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-Condensed-Bold';
	src: local('RobotoCondensedBold'), url('/fonts/RobotoCondensed-Bold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-Condensed-Regular';
	src: local('RobotoCondensedRegular'), url('/fonts/RobotoCondensed-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'BigBloke';
	src: local('BigBloke'), url('/fonts/BigBloke2Full.woff') format('woff');
	font-style: normal;
	font-display: swap;
}

.container-main {
	background-color: #F2E4D1;
	min-height: calc(100vh - 242px);
}

@media (max-width: 768px) {
	.container-main {
		min-height: calc(100vh - 182px);
	}
}

.slidedown {
	overflow-y: hidden;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
}